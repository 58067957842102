<!-- eslint-disable vue/no-v-html -->
<template>
  <Teleport to="body">
    <transition name="modal-fade">
      <div v-if="isOpen" class="modal-wrapper">
        <div class="modal-container" :style="{ width: `${widthSimpleModal}px` }">
          <div v-if="acceptClosing" class="mc-close-btn">
            <uds-icon
              icon-name="close"
              :color="udsColors.udsNeutral800"
              size="medium"
              @click="$emit('closeReject')"
            />
          </div>
          <div class="mc-title uds-headers --h3" v-html="title" />
          <div class="mc-content uds-paragraph" v-html="content" />
          <div class="mc-footer">
            <uds-main-button
              v-if="rejectButtonDisplay"
              :type="rejectButtonType"
              size="small"
              class="button-reject"
              @click="$emit('closeReject')"
            >
              {{ rejectButtonText }}
            </uds-main-button>
            <uds-main-button
              v-if="acceptButtonDisplay"
              type="primary"
              size="small"
              :loading="isLoading"
              @click="$emit('closeAccept')"
              >{{ acceptButtonText }}</uds-main-button
            >
          </div>
          <slot name="extra-content" />
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
defineEmits(["closeAccept", "closeReject"]);

withDefaults(
  defineProps<{
    isOpen: boolean;
    isLoading?: boolean;
    acceptClosing?: boolean;
    acceptButtonDisplay?: boolean;
    rejectButtonDisplay?: boolean;
    rejectButtonType?: string;
    acceptButtonText?: string;
    rejectButtonText?: string;
    title?: string;
    content?: string;
    widthSimpleModal?: number;
  }>(),
  {
    isLoading: false,
    acceptClosing: true,
    acceptButtonDisplay: true,
    rejectButtonDisplay: true,
    rejectButtonType: "tertiary",
    acceptButtonText: "Continuer",
    rejectButtonText: "Annuler",
    title: "Confirmation",
    content: "",
    widthSimpleModal: 400,
  },
);

const udsColors = getUdsColors();
</script>

<style scoped lang="scss">
.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: 0.25s ease all;
}

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;

  z-index: 99999;

  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.2);

  display: grid;
  place-items: center;

  .modal-container {
    background-color: $uds-white;
    border-radius: 3px !important;
    padding: $uds-spacing-2;
    height: max-content;
    max-width: 600px;
    margin: auto;
    position: relative;

    .mc-title {
      max-width: 96%;
      text-align: center;
      line-height: 180% !important;
    }

    .mc-content {
      padding: $uds-spacing-1 0;
      text-align: center;
      margin-bottom: 6px;
    }

    .mc-close-btn {
      position: absolute;
      right: $uds-spacing-1;
      top: $uds-spacing-1;
      cursor: pointer;
    }

    .mc-footer {
      display: flex;
      justify-content: center;
      gap: $uds-spacing-2;
    }
  }
}
</style>
